const germanStates = [
  'BE',
  'BB',
  'BW',
  'BY',
  'HE',
  'MV',
  'NW',
  'RP',
  'SL',
  'SN',
  'ST',
  'TH',
  'SH',
  'HH',
  'HB',
  'NI',
];

export default germanStates;
