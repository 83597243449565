import { appApi } from './index';
import { VoucherInfoType } from '../../types/VoucherInfoType';

export const voucherApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getVoucherInfo: builder.query<VoucherInfoType, string>({
      query: (code) => `/voucher/info/${code}`,
      transformResponse: (response: { data: VoucherInfoType }) => response.data,
    }),
  }),
});

export const { useGetVoucherInfoQuery } = voucherApi;
