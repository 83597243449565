import { Link, useParams } from 'react-router-dom';
import { useGetVoucherInfoQuery } from '../../../redux/apis/voucherApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { logout } from '../../../redux/slices/authSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { useRegisterMutation } from '../../../redux/apis/organizationApi';
import FieldError from '../../../components/form/FieldError';
import TextInput from '../../../components/form/TextInput';

const Register = () => {
  const dispatch = useAppDispatch();
  const { voucherCode } = useParams();
  const { data: voucherInfo, isLoading: isLoadingVoucherInfo } =
    useGetVoucherInfoQuery(voucherCode ?? skipToken);
  const [doRegister, { isLoading: isLoadingRegister }] = useRegisterMutation();

  const defaultFormValues = {
    name: '',
    username: '',
    password: '',
    passwordRepeat: '',
    voucherCode,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [errors, setErrors] = useState<any>({});
  const [showSuccess, setShowSuccess] = useState(false);

  const setFormValue = (key, value) => {
    setFormValues((fv) => ({
      ...fv,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setErrors({});
    if (formValues.password !== formValues.passwordRepeat) {
      setErrors({ passwordRepeat: 'match' });
      return;
    }
    doRegister(formValues)
      .unwrap()
      .then((response) => {
        setShowSuccess(true);
      })
      .catch((error) => {
        setErrors(error.data?.errors);
      });
  };

  return (
    <div className="row justify-content-center mt-5">
      {!voucherCode || !isLoadingVoucherInfo ? (
        <div className="col-12 col-md-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mb-0">Registrierung</h4>
            </div>
            {showSuccess ? (
              <div className="card-body">
                <p>Du hast dich erfolgreich registriert!</p>
                <Link to={'/login'}>Zum Login</Link>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  {voucherInfo?.status === 'REDEEMED' ? (
                    <p>
                      <div className="text-danger mt-1">
                        Dein Link wurde schon genutzt. Du kannst die normale
                        Registrierung aber fortsetzen.
                      </div>
                    </p>
                  ) : null}
                  {errors?.voucherCode ? (
                    <p>
                      <FieldError error={errors.voucherCode} />
                    </p>
                  ) : null}
                  <TextInput
                    value={formValues.name}
                    onChange={(val) => setFormValue('name', val)}
                    label="Name der Firma"
                    error={errors?.name}
                  />
                  <TextInput
                    value={formValues.username}
                    onChange={(val) => setFormValue('username', val)}
                    label="E-Mail-Adresse"
                    error={errors?.username}
                  />
                  <TextInput
                    value={formValues.password}
                    onChange={(val) => setFormValue('password', val)}
                    label="Passwort"
                    type="password"
                    error={errors?.password}
                  />
                  <TextInput
                    value={formValues.passwordRepeat}
                    onChange={(val) => setFormValue('passwordRepeat', val)}
                    label="Passwort (wiederholen)"
                    type="password"
                    error={errors?.passwordRepeat}
                  />
                  <div className="mt-4">
                    <div className="alert alert-light">
                      {voucherInfo?.status === 'VALID' &&
                      voucherInfo?.product === 'FREE_FOREVER' ? (
                        <div>Dein Account ist lebenslang kostenlos.</div>
                      ) : (
                        <div>
                          Der erste Monate ist kostenlos.
                          <br />
                          Danach fallen 1,50 € pro Nutzer pro Monat an, falls du
                          Tickly weiter nutzen möchtest.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer text-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoadingRegister}
                  >
                    Absenden
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Register;
