import { appApi } from './index';

export const organizationApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<
      boolean,
      { name: string; username: string; password: string; voucherCode: string }
    >({
      query: (payload) => ({
        url: '/organization/register',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: { data: boolean }, meta, arg) =>
        response.data,
    }),
  }),
});

export const { useRegisterMutation } = organizationApi;
